<template>
  <div class="main">
    <v-btn
      v-if="config.emailBtn"
      :style="
        !loadingEmail
          ? 'background:#03A9F4;color:white;'
          : 'background: grey; color:white;'
      "
      :disabled="!!loadingEmail"
      @click.stop="openMessageDialog"
    >
      Email {{ !loadingEmail ? ' ' + config.title : 'Sending....' }}
    </v-btn>
    <v-dialog max-width="400px" v-model="messageDialogStatus" persistent>
      <v-card class="pa-4">
        <v-card-title primary-title>Email Confirmation</v-card-title>
        <v-card-text
          >Are you sure you want to send email to {{ userEmail }} ?</v-card-text
        >
        <v-divider></v-divider>
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="closeMessageDialog"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click.stop="onDownloadClicked(true)"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['configuration', 'dataSource'],
  data: () => ({
    dataProvider: {},
    config: {},
    loader: {},
    loadingEmail: false,
    messageDialogStatus: false,
    userEmail: 'abcd@kennect.io'
  }),
  created: function () {
    console.log('========Email==========', this)
    this.config = JSON.parse(JSON.stringify(this.configuration || {}))
    // if (
    //   this.dataSource &&
    //   this.dataSource.headers &&
    //   this.dataSource.headers.length
    // ) {
    //   if (this.dataSource.rows.length) {
    //     this.dataProvider = this.dataSource.rows
    //   } else {
    //     this.dataProvider = this.makeDataProvider(this.dataSource)
    //   }
    // }
  },
  methods: {
    openMessageDialog() {
      this.messageDialogStatus = true
    },
    closeMessageDialog() {
      this.messageDialogStatus = false
    },
    onDownloadClicked: function (clickedForEmail) {
      console.log('EmailSelfClicked')
      return
      let maxEventTime = 1000
      this.messageDialogStatus = false
      // this.loading = true;
      if (this.config) {
        let config = {
          chartName: this.config.name,
          title: this.config.title
        }
        if (this.filterApplied) {
          config.exportparams = JSON.stringify(this.filterApplied)
        }
        if (clickedForEmail) {
          this.loadingEmail = true
          K.network.downloadFile('email_xlsx', config)
          this.loader['email_xlsx'] = setInterval(() => {
            if (
              context.getters.snackbarText == 'Email Sent' ||
              context.getters.snackbarText == 'Email not Sent'
            ) {
              this.loadingEmail = false
              this.clearInterval(this.loader['email_xlsx'])
            }
          }, maxEventTime)
        } else {
          this.loadingDownload = true
          K.network.downloadFile('xlsx', config)
          this.loader['xlsx'] = setInterval(() => {
            if (
              context.getters.snackbarText == 'Download Successfull' ||
              context.getters.snackbarText == 'Download failed'
            ) {
              this.loadingDownload = false
              this.clearInterval(this.loader['xlsx'])
            }
          }, maxEventTime)
        }
      }
    },
    clearInterval: (t) => {
      clearInterval(t)
    }
  },
  watch: {
    loadingEmail() {
      console.log('LOADING....', this.loadingEmail)
    }
  }
}
</script>
    
